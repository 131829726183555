html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #2c3e50;
}

#app {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background-color: white;
}

#nav {
  padding: 20px;
}

a {
  color: #2c3e50;
  text-decoration: none;
}

#nav a {
  font-weight: bold;
}

.footer {
  padding: 10px;
  font-family: 'Raleway', sans-serif;
  color: #2c3e50;
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #2c3e50;
  text-decoration: underline;
  font-weight: bold;
}

#nav a {
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: normal;
}

a:hover {
  text-decoration: underline;
  color: #004e66c4;
}

#header {
  padding-top: 20px;
  font-family: 'Raleway-ExtraLight';
  font-size: 12px;
  color: #2c3e50;
}

#header a {
  text-decoration: none;
  color: #2c3e50;
}

#header .initials {
  line-height: 48px;
  font-size: 48px;
}

#header .name {
  font-family: 'Raleway-SemiBold';
  margin-top: -6px;
}

#header address {
  margin-bottom: 0px;
}

#header .contact {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

#header hr {
  height: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  opacity: 1;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

#header .hr1 {
  color: #adad85;
}

#header .hr2 {
  color: #2c3e50;
  width: 49%;
}

.col-md-auto {
  padding-left: 5px;
  padding-right: 5px;
}

.col-sm-auto {
  padding-left: 0px;
  padding-right: 0px;
}

.homeimg {
  width:75%
}

/***************
START Media Queries - RESPONSIVE FOR SMALLER MOBILE SCREENS
***************/
@media handheld and (max-width: 480px), screen and (max-device-width: 480px), screen and (max-width: 480px) {
  #nav {
    padding-left: 0px;
    padding-right: 0px;
  }

  #nav a {
    text-decoration: none;
    margin-left: 5px;
    margin-right: 5px;
  }

  .col-sm-auto {
    display: none;
  }

  #header .hr1 {
    width: 95%;
  }

  #header .hr2 {
    width: 94%;
  }

  footer {
    font-size: 10px;
  }

  #nav {
    font-size: 12px;
  }

  .list-group-item {
    font-size: 12px;
  }

  .homeimg {
    width:100%
  }
}
/***************
END Media Queries
***************/
